<template>

  <v-dialog
    width="600"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6">
        <span>Activate vote format</span>
        <span><span class="font-weight-black">{{voteFormat.name}}</span>?</span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-actions class="d-flex align-center justify-center">
        <v-btn depressed width="130" @click="show=false" class="mr-2">No</v-btn>
        <v-btn depressed width="130" class="ml-2" color="secondary" @click="activateVoteFormat">Yes</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: 'VoteFormatActivate',
  props: {
    voteFormat: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    form: {
      status: 'active'
    },
    saving: false
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    activateVoteFormat () {
      this.saving = true
      axios.put(process.env.VUE_APP_BACKEND_URL + '/api/vote-formats/' + this.voteFormat.id, this.form)
        .then(() => {
          this.saving = false
          this.show = false
          this.$emit('reload', true)
        })
    }
  }

})
</script>
